import React from "react";

export default function HeaderFooterPage() {

    return (
        <>
        <footer>
                <p>&copy; Copyright 2024 - All Rights Reserved</p>
        </footer>
        </>
);
}
